/* eslint-disable prefer-destructuring */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API, Auth } from 'aws-amplify';

const getUserAccounts = createAsyncThunk(
  'account/getUserAccounts',
  async (userSub: string, thunkAPI) => {
    const token = (await Auth.currentSession()).getAccessToken().getJwtToken();
    const res = await API.get('openscreen', `users/${userSub}/accounts`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return (await res) as any;
  }
);

const createNewAccount = createAsyncThunk(
  'account/createNewAccount',
  async (
    {
      companyName,
      userSub,
    }: {
      companyName: string;
      userSub: string;
    },
    thunkAPI
  ) => {
    console.log(companyName, userSub);
    const token = (await Auth.currentSession()).getAccessToken().getJwtToken();
    const myInit = {
      body: {
        companyName,
        renewalPricePlanId: '93441171-263b-41ba-87ce-24b873c5e4d0',
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const res = await API.post(
      'openscreen',
      `users/${userSub}/accounts`,
      myInit
    );
    return (await res) as any;
  }
);

const getBillingLink = createAsyncThunk(
  'account/getBillingLink',
  async (
    {
      accountId,
      location,
    }: {
      accountId: string;
      location: string;
    },
    thunkAPI
  ) => {
    const token = (await Auth.currentSession()).getAccessToken().getJwtToken();
    const myInit = {
      body: {
        location,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const res = await API.post(
      'openscreen',
      `billing/session/${accountId}`,
      myInit
    );
    return (await res) as any;
  }
);

const accountSlice = createSlice({
  name: 'account',
  initialState: {
    account: [],
    currentAccount: null,
    billingLink: null,
    loading: true,
  },
  reducers: {
    setCurrentAccount: (state, action) => {
      state.account.unshift(state.currentAccount);
      const filteredState = state.account.filter(
        (ele) => ele.accountId !== action.payload.accountId
      );
      state.account = filteredState;
      state.currentAccount = action.payload;
    },
    setAccount: (state, action) => {
      state.currentAccount = action.payload;
    },
    changeAccountName: (state, action) => {
      state.currentAccount.companyName = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserAccounts.fulfilled, (state, action) => {
      if (action.payload.accounts.length === 1) {
        state.account = action.payload.accounts;
        state.currentAccount = action.payload.accounts[0];
      } else {
        const sortedByModified = action.payload.accounts.sort(
          (a: any, b: any) => {
            const aDate = new Date(a.modified).getTime();
            const bDate = new Date(b.modified).getTime();
            return bDate - aDate;
          }
        );
        state.account = sortedByModified.slice(1);
        state.currentAccount = action.payload.accounts[0];
      }
      state.loading = false;
    });
    builder.addCase(createNewAccount.fulfilled, (state, action) => {
      state.currentAccount = action.payload;
      // return action.payload;
    });
    builder.addCase(getBillingLink.fulfilled, (state, action) => {
      state.billingLink = action.payload;
    });
    builder.addCase(createNewAccount.rejected, (state, action) => {
      console.error(action.error);
    });
    builder.addCase(getUserAccounts.rejected, (state, action) => {
      console.error(action.error);
    });
  },
});

export const { setCurrentAccount, setAccount, changeAccountName } =
  accountSlice.actions;
export { getUserAccounts, createNewAccount };
export default accountSlice;
