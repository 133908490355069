import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API, Auth } from "aws-amplify";

const getAccountProjects = createAsyncThunk(
  "project/getAccountProjects",
  async (accountId: string, thunkAPI) => {
    const token = (await Auth.currentSession()).getAccessToken().getJwtToken();
    const res = await API.get("openscreen", `accounts/${accountId}/projects`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return (await res) as any;
  }
);

const ProjectSlice = createSlice({
  name: "projects",
  initialState: {
    projects: [],
    loading: true,
  },
  reducers: {
    addProject: (state, action) => {
      state.projects.unshift(action.payload);
    },
    removeAllProjects: (state) => {
      state.projects = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAccountProjects.fulfilled, (state, action) => {
      const sortedByModified = action.payload.projects.sort(
        (a: any, b: any) => {
          const aDate = new Date(a.modified).getTime();
          const bDate = new Date(b.modified).getTime();
          return bDate - aDate;
        }
      );
      state.projects = sortedByModified;
      state.loading = false;
    });
    builder.addCase(getAccountProjects.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const { addProject, removeAllProjects } = ProjectSlice.actions;
export { getAccountProjects };
export default ProjectSlice;
