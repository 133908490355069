/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
 "Auth": {
  "region": "us-east-1",
  "userPoolId": "us-east-1_P0P30Nw9q",
  "userPoolWebClientId": "5q5e9hr69lq1n79bvsqdg82lta"
 },
 "API": {
  "endpoints": [
   {
    "name": "openscreen",
    "endpoint": "https://zwf11pu2qe.execute-api.us-east-1.amazonaws.com/prod/"
   }
  ]
 }
}
export default awsmobile;
