import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Amplify, { Auth } from 'aws-amplify';
import { navigate } from 'gatsby';
// import { setUser, getCurrentUser } from "../../utils/authService";
import awsmobile from '../../utils/aws-exports';

Amplify.configure(awsmobile);

interface userData {
  username: string;
  password: string;
}

const signIn = createAsyncThunk(
  'user/signIn',
  async (data: userData, thunkAPI) => {
    const res = await Auth.signIn(data.username.toLowerCase(), data.password);
    return (await res.attributes) as any;
  }
);

const signOut = createAsyncThunk('user/signOut', async (thunkAPI) => {
  const res = await Auth.signOut();
  return (await res) as any;
});

const userSlice = createSlice({
  name: 'user',
  initialState: {
    userId: null,
    user: null,
  },
  reducers: {
    setInitialUser(state, action) {
      state.user = action.payload;
      state.userId = action.payload.sub;
    },
    updateUserAttributes(state, action) {
      const testing = {
        ...action.payload,
      };
      state.user = testing;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      signIn.fulfilled,
      (state: any, action: { payload: any }) => {
        state.user = action.payload;
        state.userId = action.payload.sub;
        navigate('/app');
      }
    );
    builder.addCase(signIn.rejected, (state: any, action: any) => {});

    builder.addCase(
      signOut.fulfilled,
      (state: { user: any; userId: string | null }, action: any) => {
        state.user = {};
        state.userId = null;
        navigate('/signin');
      }
    );
  },
});

export const { setInitialUser, updateUserAttributes } = userSlice.actions;
export { signIn, signOut };

export default userSlice;
