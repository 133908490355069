import { extendTheme } from '@chakra-ui/react';
import './fonts.css';

const theme = {
  fonts: {
    heading: 'Circular Std Book',
    body: 'Circular Std Book',
  },
  colors: {
    primary: '#0CB7C3',
  },
};
export default extendTheme(theme);
