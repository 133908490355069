import { configureStore, DeepPartial } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import userSlice from "./user/userSlice";
import accountSlice from "./account/account";
import projectSlice from "./project/projectSlice";
import apiKeySlice from "./apiKey/apiKeySlice";

let store = null;

const state = (preloadedState: DeepPartial<any>) => {
  store = configureStore({
    reducer: {
      user: userSlice.reducer,
      account: accountSlice.reducer,
      projects: projectSlice.reducer,
      apiKeys: apiKeySlice.reducer,
    },
    preloadedState,
  });
  return store;
};
export type AppDispatch = typeof store;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export default state;
