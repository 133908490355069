/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API, Auth } from 'aws-amplify';

const createNewKey = createAsyncThunk(
  'apiKey/create',
  async (accountId: string, thunkAPI) => {
    const token = (await Auth.currentSession()).getAccessToken().getJwtToken();
    const res = await API.post('openscreen', `accounts/${accountId}/apikeys`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: {
        name: 'Primary API Key',
      },
    });
    return (await res) as any;
  }
);

const getExistingKeys = createAsyncThunk(
  'apiKey/get',
  async (accountId: string, thunkAPI) => {
    try {
      const token = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();
      const res = await API.get('openscreen', `accounts/${accountId}/apikeys`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return (await res) as any;
    } catch (error) {
      console.log(error);
    }
  }
);

const generateNewSecret = createAsyncThunk(
  'apiKey/generate',
  async (keyId: string, thunkAPI) => {
    const token = (await Auth.currentSession()).getAccessToken().getJwtToken();
    const res = await API.patch('openscreen', `apikeys/${keyId}/secret`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return (await res) as any;
  }
);

const apiKeySlice = createSlice({
  name: 'apiKey',
  initialState: {
    key: null,
    loading: true,
  },
  reducers: {
    resetKey(state) {
      state.key = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createNewKey.fulfilled, (state, action) => {
      state.key = { ...action.payload };
      state.loading = false;
    });
    builder.addCase(createNewKey.rejected, (state, action) => {});

    builder.addCase(getExistingKeys.fulfilled, (state, action) => {
      if (action.payload) {
        if (action.payload.length === 0) {
          state.key = null;
        }
        if (action.payload.length > 0) state.key = { ...action.payload[0] };
        state.loading = false;
      }
    });
    builder.addCase(getExistingKeys.rejected, (state, action) => {
      console.log(action.error);
    });

    builder.addCase(generateNewSecret.fulfilled, (state, action) => {
      state.key = { ...action.payload };
      state.loading = false;
    });
    builder.addCase(generateNewSecret.rejected, (state, action) => {});
  },
});

export const { resetKey } = apiKeySlice.actions;
export { getExistingKeys, createNewKey, generateNewSecret };
export default apiKeySlice;
